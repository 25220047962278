import React from "react"
import { Link } from "gatsby"
import { linkResolver } from "../../utils/linkResolver"

const MenuItem = ({ menuItem }) => {
  const resolvedLink = linkResolver(menuItem)
  return resolvedLink.isExternal ? (
    <a
      href={resolvedLink.url}
      target='_blank'
      rel='noopener noreferrer'
      className='transition duration-200 ease-in-out bg-primary font-semibold uppercase mx-3 mega:mx-4 px-2 mega:px-4 py-2 rounded hover:bg-hover text-white text-sm mega:text-base hover:text-darkslategray'>
      <span>{menuItem.text}</span>
    </a>
  ) : (
    <Link
      className='transition duration-200 ease-in-out bg-primary font-semibold uppercase mx-3 mega:mx-4 px-2 mega:px-4 py-2 rounded hover:bg-hover text-sm mega:text-base text-white hover:text-darkslategray'
      to={resolvedLink.url}>
      <span>{menuItem.text}</span>
    </Link>
  )
}

export default MenuItem
