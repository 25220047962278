export const linkResolver = doc => {
  if (doc.menuitemkey === "home") {
    return { isExternal: false, url: "/" }
  }
  if (doc.menuitemkey === "websites") {
    return { isExternal: false, url: "/services/websites" }
  }
  if (doc.menuitemkey === "photography") {
    return { isExternal: false, url: "/services/photography" }
  }
  if (doc.menuitemkey === "blog") {
    return { isExternal: false, url: "/blog" }
  }

  if (doc.menuitemkey === "contact-us") {
    return { isExternal: false, url: "/contact-us" }
  }

  if (doc.menuitemkey === "copywriting") {
    return { isExternal: false, url: "/services/copywriting" }
  }

  if (doc.menuitemkey === "logodesign") {
    return { isExternal: false, url: "/services/logodesign" }
  }

  if (doc._linkType === "Link.document" && doc._meta.uid === "about-us") {
    return { isExternal: false, url: "/about-us" }
  }

  if (doc._linkType === "Link.document" && doc._meta.uid === "programs") {
    return { isExternal: false, url: "/programs" }
  }

  if (doc._linkType === "Link.document" && doc._meta.uid === "playscapes") {
    return { isExternal: false, url: "/playscapes" }
  }

  if (doc._linkType === "Link.document" && doc._meta.uid === "admissions") {
    return { isExternal: false, url: "/admissions" }
  }

  if (doc._linkType === "Link.document" && doc._meta.uid === "schedule-your-visit") {
    return { isExternal: false, url: "/schedule-your-visit" }
  }

  if (doc._linkType === "Link.file" && doc.menuitemkey === "calendar") {
    return { isExternal: true, url: doc.link_url.url }
  }

  if (doc.link_url && doc.link_url._linkType === "Link.file" && doc.menuitemkey === "calendar") {
    return { isExternal: true, url: doc.link_url.url }
  }

  // Backup for all other types
  return { isExternal: false, url: "/" }
}
