import React, { useState, useEffect } from "react"
import { useCookies } from "react-cookie"

export const PrivacyPolicy = props => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies()

  const hasAcknowledgedPrivacyPolicy = "hasAcknowledgedPrivacyPolicy"
  useEffect(() => {
    setTimeout(() => {
      if (!cookies[hasAcknowledgedPrivacyPolicy]) {
        setShowPrivacyPolicy(true)
      }
      if (cookies[hasAcknowledgedPrivacyPolicy]) {
        setShowPrivacyPolicy(false)
      }
    }, 500)
  })

  return (
    <div
      className='privacy-policy-container'
      style={{
        opacity: `${showPrivacyPolicy ? 1 : 0}`,
        display: `${showPrivacyPolicy ? "flex" : "none"}`,
        transition: `all 100ms linear 0s`
      }}>
      <div
        className='privacy-policy-content'
        onClick={() => {
          setCookie(hasAcknowledgedPrivacyPolicy, true)
          setShowPrivacyPolicy(false)
        }}>
        <div className='text-xs sm:text-sm' style={{ textAlign: `center` }}>
          By using this website you acknowledge and give consent to our{" "}
          <a
            rel='noreferrer noopener'
            target='_blank'
            href='/privacypolicy.html'
            style={{ color: `gray` }}>
            Privacy Policy
          </a>
          <br />
        </div>
        <div className='privacy-policy-content-close'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='15'
            height='15'
            viewBox='0 0 24 24'
            fill='none'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'>
            <line x1='18' y1='6' x2='6' y2='18'></line>
            <line x1='6' y1='6' x2='18' y2='18'></line>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
