import React, { useState } from "react";
// import { DefaultNavbar, NavbarContainer, SmallScreenNavbar } from "./navbar"
import FullWidthContainer from "./containers/FullWidthContainer";
import { FooterContainer, FooterContent } from "./footer/Footer";
import MenuSidebar from "./menu/MenuSidebar";
import PrivacyPolicy from "./privacyPolicy";
import Header from "./Header";
import BackgroundImage from "gatsby-background-image";
import { Helmet } from "react-helmet";

export default props => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    showTitleInNavbar,
    bgColorClass,
    isServiceLayout,
    useTallBannerBackground,
    menuItems,
    heroSlantClassName,
    backgroundStyle,
    contentInsideBackground,
    children
  } = props;


  const closeMenu = () => (isMenuOpen ? setIsMenuOpen(0) : {});

  let content = (
    <div className='mx-auto h-full flex flex-col' onClick={() => closeMenu()} role='document'>
      <FullWidthContainer role='article'>
        <Header
          menuItems={menuItems}
          isServiceLayout={isServiceLayout}
          // logoFluid={companyLogoFluid}
          // logoAlt={companyLogoAlt}
          // logoClassName={logoClassName}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
      </FullWidthContainer>
      {contentInsideBackground ? contentInsideBackground() : null}
    </div>
  );

  const backgroundComponentLarge = (
    <div
      style={
        backgroundStyle
          ? backgroundStyle
          : {
            height: `${useTallBannerBackground ? "150%" : "100%"}`,
            paddingBottom: isServiceLayout ? "0" : "6rem",
            background: `center / cover no-repeat linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.50)), url("https://res.cloudinary.com/frannsoft/image/upload/w_1800,f_auto,q_auto,fl_lossy/v1577815211/redbrickroadstudio/homebackground_op.jpg")`,
            backgroundSize: `cover`,
            backgroundPosition: `center center`,
            backgroundRepeat: "no-repeat"
          }
      }>
      {content}
    </div>
  );

  const backgroundComponentSmall = (
    <BackgroundImage
      Tag='div'
      fadeIn='critical'
      fluid={[
        `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.50)), url("https://res.cloudinary.com/frannsoft/image/upload/w_1800,f_auto,q_auto,fl_lossy/v1577815211/redbrickroadstudio/homebackground_op.jpg")`
      ]}
      style={
        backgroundStyle
          ? backgroundStyle
          : { paddingBottom: isServiceLayout ? "0rem" : "6rem", height: `100%` }
      }>
      {content}
    </BackgroundImage>
  );

  return (
    <div style={{ ...props.style }} className='flex flex-col justify-between'>
      <Helmet title={props.helmetTitle} >
        <meta charSet='utf-8' />
        <meta name="author" content="Red Brick Road Studio at https://redbrickroadstudio.com" />
        <meta name="description" content="Red Brick Road Studio specializes in custom website design, logo design, copywriting and written content creation and editing and photography services. We serve Northeast Ohio and are located in Westlake Ohio. We love serving small and medium sized business" />
        <meta name="keywords" content="www.redbrickroadstudio.com, logo designers cleveland ohio, copywriting cleveland ohio, photography services cleveland ohio, website designers Cleveland ohio, website design company cleveland Ohio, website designer cleveland ohio, web site design cleveland, website creation westlake ohio 44145" />
        <meta name="google-site-verification" content="p7BHKwgrxXQAX8JGbv1lo9c6uh49wd1T8INgnZbkUjE" />

      </Helmet>
      {isMenuOpen ? <div className='fixed h-full z-10 w-full' onClick={() => closeMenu()} /> : null}

      <main
        className={`${heroSlantClassName} mobile:height-hero-xs sm:height-hero-sm md:height-hero-full flex flex-col `}>
        <>
          <div className='block lg:hidden h-full'>{backgroundComponentSmall}</div>
          <div className='hidden lg:block h-full'>{backgroundComponentLarge}</div>
        </>
      </main>
      {/* <div className='mx-auto h-full flex flex-col' onClick={() => closeMenu()} role='document'>
        <FullWidthContainer role='article'>
          <Header
            menuItems={menuItems}
            // logoFluid={companyLogoFluid}
            // logoAlt={companyLogoAlt}
            // logoClassName={logoClassName}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        </FullWidthContainer>
        <div>{props.children}</div>
      </div> */}

      <MenuSidebar
        onMenuClick={closeMenu}
        // logoFluid={sidebarLogoFluid}
        // logoAlt={sidebarLogoAlt}
        menuItems={menuItems}
        className={`${isMenuOpen ? "transition-onscreen-from-right" : "offscreen-right"}`}
      />
      {children}
      {/* </animated.div> */}
      <PrivacyPolicy />
      {/* <script src="https://f.convertkit.com/ckjs/ck.5.js"></script>
      <div className="flex justify-center mb-12" dangerouslySetInnerHTML={buildNewsletterForm()} /> */}
      <FooterContainer>
        <FooterContent />
      </FooterContainer>
    </div>
  );
};


