import React from "react";
import styled from "styled-components";
import { ContactUsButton } from "../ContactUsButton";
import { FooterLink } from "./FooterLink";

export const FooterContainer = styled.footer`
  margin: 0 0 0 0;
  width: 100%;
  background-color: #1f1f1f;
`;

export const FooterContent = props => {
  return (
    <div className='text-gray-100 p-4 text-center text-sm md:text-base'>
      <div className='flex items-top justify-between max-w-3xl mx-auto mt-4 mb-6 px-2'>
        <address className='not-italic text-left'>
          <div className='mb-4'>
            <FooterLink to="/" name="Red Brick Road Studio" />
            <p>Westlake, OH</p>
          </div>
          <ContactUsButton actionText='Contact Us' />
        </address>
        <div className='text-left'>
          <h3 className='font-bold text-base'>Services</h3>
          <div className='flex flex-col'>
            <FooterLink to='/services/photography' name='Photography' />
            <FooterLink to='/services/websites' name='Websites' />
            <FooterLink to='/services/copywriting' name='Copywriting & Editing' />
            <FooterLink to='/services/logodesign' name='Logo Design' />
          </div>
        </div>
      </div>
      <div className='mt-4'>
        <span>Red Brick Road Studio, ©2021</span>
      </div>
    </div>
  );
};
