export const baseUrl = process.env.REACT_APP_STRAPI_BASE_URL
  ? process.env.REACT_APP_STRAPI_BASE_URL
  : "https://redbrickroadstudio.com" //"https://sleepy-badlands-23206.herokuapp.com" //"http://localhost:1337"

export const phoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const menuItems = [
  { menuitemkey: "websites", text: "Websites", isExternal: false, url: "/services/websites" },
  {
    menuitemkey: "photography",
    text: "Photography",
    isExternal: false,
    url: "/services/photography"
  },
  {
    menuitemkey: "copywriting",
    text: "Copywriting & Editing",
    isExternal: false,
    url: "/services/copywriting"
  },
  {
    menuitemkey: "logodesign",
    text: "Logo Design",
    isExternal: false,
    url: "/services/logodesign"
  },
  { menuitemkey: "blog", text: "Blog", isExternal: false, url: "/blog" }
]
