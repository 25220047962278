import React from "react"
import { Link } from "gatsby"

export const FooterLink = props => {
  return (
    <Link to={props.to} className='text-gray-100 hover:underline'>
      {props.name}
    </Link>
  )
}
