import React from "react"
import { Link } from "gatsby"

const NavbarSmall = ({ isMenuOpen, setIsMenuOpen, isServiceLayout }) => {
  return (
    <nav
      className={`flex lg:hidden flex-row items-center ${
        isServiceLayout ? "justify-end lg:justify-between" : "justify-end"
      } `}>
      {isServiceLayout ? (
        <Link className={`w-full flex flex-col justify-center`} to='/'>
          <h2 className='font-bold uppercase text-gray-100'>Red Brick Road Studio</h2>
        </Link>
      ) : null}
      <div className='flex items-center'>
        <button
          className='flex items-center px-3 py-2 text-gray-100 border-primary'
          onClick={() => {
            setIsMenuOpen(!isMenuOpen)
          }}>
          <svg
            className='fill-current h-5 w-5'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'>
            <title>Menu</title>
            <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
          </svg>
        </button>
      </div>
    </nav>
  )
}

export default NavbarSmall
