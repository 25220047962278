import React from "react"
import { Link } from "gatsby"

export const ContactUsButton = props => {
  const { actionText, compact } = props

  const textSize = compact ? "text-lg" : "text-xl"
  return (
    <div
      className={`inline-block ${textSize} rounded bg-primary hover:bg-teal-500 border border-primary hover:border-hover text-gray-100 px-4 py-2`}>
      <Link to='/contact-us'>
        <div className='font-semibold'>
          <span>{actionText}</span>
        </div>
      </Link>
    </div>
  )
}
