import React from "react"
import NavbarLarge from "./navbar/NavbarLarge"
import NavbarSmall from "./navbar/NavbarSmall"
// import Logo from './logo';

const Header = ({
  logoFluid,
  logoAlt,
  menuItems,
  logoClassName,
  isMenuOpen,
  setIsMenuOpen,
  isServiceLayout
}) => (
  <header>
    <div className='flex flex-col justify-center mx-auto pt-6 md:pt-10 pb-4'>
      <NavbarLarge menuItems={menuItems} isServiceLayout={isServiceLayout} />
      <NavbarSmall
        menuItems={menuItems}
        isServiceLayout={isServiceLayout}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
      />
      <div className='md:w-1/3 lg:w-1/4'>
        {/* <Logo logoFluid={logoFluid} className={`mx-auto md:mx-0 ${logoClassName}`} alt={logoAlt} /> */}
      </div>
    </div>
  </header>
)

export default Header
