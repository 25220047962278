import React from "react"
import { Link } from "gatsby"
import MenuItem from "../menu/MenuItem"
import { ContactUsButton } from "../ContactUsButton"

const NavbarLarge = ({ menuItems, isServiceLayout }) => {
  return (
    <nav
      className={`hidden lg:flex flex-row items-center  ${
        isServiceLayout ? "justify-end lg:justify-between" : "justify-end"
      } `}>
      {isServiceLayout ? (
        <Link className={`flex flex-col justify-center lg:ml-6`} to='/'>
          <h2 className='font-bold uppercase text-white'>Red Brick Road Studio</h2>
        </Link>
      ) : null}
      <div className='hidden lg:flex flex-row items-center justify-end'>
        {menuItems.map((menuItem, index) => {
          return <MenuItem key={index} menuItem={menuItem} />
        })}
        <ContactUsButton actionText='Contact Us' />
      </div>
    </nav>
  )
}

export default NavbarLarge
