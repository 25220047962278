import React from "react"
import { Link } from "gatsby"
import { linkResolver } from "../../utils/linkResolver"
import { ContactUsButton } from "../ContactUsButton"

const MenuItemSidebar = ({ menuItems, className }) => {
  return (
    <div className='flex flex-col px-2'>
      <Link className={`w-full flex flex-col justify-center lg:ml-6`} to='/'>
        <h2 className='font-bold text-lg ml-4 my-6 uppercase'>Red Brick Road Studio</h2>
      </Link>
      {menuItems.map((menuItem, index) => {
        const resolvedLink = linkResolver(menuItem)

        return (
          <div key={index} className={`text-gray-700 font-medium uppercase ${className}`}>
            {index > 0 ? <div className='bg-gray-500 h-px'></div> : null}
            {resolvedLink.isExternal ? (
              <a
                href={resolvedLink.url}
                target='_blank'
                rel='noopener noreferrer'
                className='hover:underline hover:text-primary hover:font-medium'>
                <div className='px-1' style={{ padding: `0.65rem 0` }}>
                  <span>{menuItem.text}</span>
                </div>
              </a>
            ) : (
              <Link
                className='hover:underline hover:text-primary hover:font-medium'
                to={resolvedLink.url}>
                <div className='px-1' style={{ padding: `0.65rem 0` }}>
                  <span>{menuItem.text}</span>
                </div>
              </Link>
            )}
          </div>
        )
      })}
      <ContactUsButton actionText='Contact Us' />
    </div>
  )
}

const MenuSidebar = ({ onMenuClick, logoFluid, menuItems, logoAlt, className }) => {
  return (
    <div
      className={`z-50 fixed inset-y-0 h-full bg-white w-2/3 lg:1/4 md:w-1/3 shadow-xl ${className}`}>
      <div className='flex flex-col h-full justify-between menu-scroll'>
        <div className='flex flex-col'>
          <div
            className='self-end mt-4 mr-4 h-6 cursor-pointer'
            onClick={onMenuClick}
            role='menu'
            tabIndex={0}>
            <div
              className='bg-gray-600 mt-2 w-6'
              style={{
                height: `0.1rem`,
                transform: `translateY(0.37rem) rotate(45deg)`
              }}
            />
            <div
              className='bg-gray-600 w-6'
              style={{
                height: `0.1rem`,
                transform: `translateY(0.3rem) rotate(-45deg)`
              }}
            />
          </div>
          <div className='w-full mt-2 md:mt-4 tiny:mb-4 mobile:mb-6 sm:mb-8'>
            {/* <Logo
              logoFluid={logoFluid}
              className='max-w-xxxxs sm:max-w-xxxs mx-auto'
              alt={logoAlt}
            /> */}
          </div>
          <MenuItemSidebar menuItems={menuItems} className='mx-4' />
        </div>
      </div>
    </div>
  )
}

export default MenuSidebar
